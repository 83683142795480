<template>
<div class="CategorySection story">
  <div class="imageAndTextMargin">
    <div class="imageAndTextWrapper">
      <div class="sticky">
        <SubHeading class="subHeading" style="margin-bottom: 16px" title="TEAM" uniqueId="teamSubHeading"/>
        <Heading class="heading" style="margin-bottom: 20px" title="WHO WE ARE" uniqueId="teamHeading" speed="30"/>
        <div v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'text')" class="storyText" :class="{ 'animate-text-block' : textVisible }">
          <p>We are a team of data scientists, foresight experts and designers.</p>
          <p><b>We are always looking for mutually beneficial partnerships and collaborations.</b></p>
          <div class="contactButtonWrapper">
            <a class="contactButton" href= "mailto:contact@nft-world.news">Send Email</a>
          </div>
        </div>
      </div>
      <div class="imageWrapper">
        <div class="avatarWrapper">
          <TeamMember v-for="teamMember in teamMembers" :key="teamMember.id" class="floatLeft" :imgSrc="teamMember.image" :avatarName="teamMember.name" :avatarDesc="teamMember.title" 
          v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, teamMember)" :class="{ 'opacityFadeEffect' : teamMember.isInViewPort }"/>
        </div>
      </div>
    </div>
  </div>
  <div class="aspect-ratio-box aspect-square"><lord-icon trigger="loop" class="aspect-ratio-box-inside editor-preview"></lord-icon></div>
</div>
</template>

<script>
// @ is an alias to /src
import SubHeading from '@/components/TextElements/SubHeading.vue'
import Heading from '@/components/TextElements/Heading.vue'
import TeamMember from '@/components/Team/MemberCard.vue'

// images
import DesignerImg from '../../assets/team/NF-Tron-Profilbild-Designer.png'
import RainmanImg from '../../assets/team/NF-Tron-Profilbild-Rainman.png'
import ScientistImg from '../../assets/team/NF-Tron-Profilbild-Scientist.png'
import WorldImproverImg from '../../assets/team/NF-Tron-Profilbild-World_Improver.png'
export default {
  name: 'Team',
  components: {
    SubHeading,
    Heading,
    TeamMember
  },
  props: [],
  data() {
        return {
            textVisible: false,
            imageVisible: false,
            teamMembers : [
              {
                id : '1',
                name : 'RAINMAN',
                title : 'CO-FOUNDER',
                image : RainmanImg,
                isInViewPort : false,
              },
              {
                id : '2',
                name : 'SCIENTIST',
                title : 'CO-FOUNDER',
                image : ScientistImg,
                isInViewPort : false,
              },
              {
                id : '3',
                name : 'WORLD IMPROVER',
                title : 'CO-FOUNDER',
                image : WorldImproverImg,
                isInViewPort : false,
              },
              {
                id : '4',
                name : 'DESIGNER',
                title : 'CO-FOUNDER',
                image : DesignerImg,
                isInViewPort : false,
              }
            ], 
        }
  },
  methods: {
    visibilityChanged (isVisible, entry, element) {
      if (element == 'text') {
        this.textVisible = isVisible;
      } else if (isVisible) {
        element.isInViewPort = isVisible;
      } else {
        element.isInViewPort = isVisible;
      }

    },
  },
  mounted() {} 
}
</script>

<style scoped>

.contactButton{
    background: rgb(74, 74, 76);
    border: 1px solid rgb(74, 74, 76);
    padding: 10px 30px;
    border-radius: 20px;
    color: white;
    text-decoration: none;
}

.avatarWrapper{
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  text-align: center;
}

.floatLeft{
  display: inline-block;
  margin: 7px;
}

.contactButtonWrapper{
  margin-top: 25px;
}

.heading{
  max-width: 600px;
  text-align: left;
}
.subHeading{
    text-align: left;
}
.story{
  text-align: center
}
.sticky{
  margin-right: 5%;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 10px;
  align-self: flex-start; /* <-- this is the fix */
  padding-top: 75px;
}
@media screen and (max-width: 1096px) {
  .sticky{
      padding-top: 0px;
  }
}
.horizontal{
  display: block;
  border-top: 4px solid #ccc;
  border-radius: 50px;
  margin: 1.5em 0;
  padding: 0;
  width: 50px;
  margin-left: auto;
  margin-right: auto;
}
p{
  max-width: 600px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageAndTextMargin{
    z-index: 1;
    width: 100%;
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px;
}
.imageAndTextWrapper{
    display: flex;
}

.storyText{
  text-align: left;
}

@media screen and (max-width: 1311px) {
  .imageAndTextWrapper{
      margin: 0px;
  }
  .imageWrapper{
    margin: auto;
  }
  .storyText{
    margin-bottom: 25px;
    
  }
}

@media screen and (max-width: 1096px) {
  .imageAndTextWrapper{
      display: flex;
      /**margin: 0px +150px -15px;**/
      flex-flow: row-reverse wrap;
  }
  .sticky{
    width: 100%;
    position: relative;
    margin-left: 0px;
  }
  .heading{
    max-width: 100%;
  }
  p{
    max-width: 100%;
  }
  .imageWrapper{
    margin-top: 20px;
  }
}

.imageWrapper{
    flex: 1 1 50%;
    margin-right: auto;
    width: 50%;
}

.storyImage{
    padding-right: 0px;
    border: 0px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    object-fit: contain;
    border-radius: 10px;
    width: 100%;
    object-fit: contain;
    max-height: inherit;
}

</style>
