<template>
<div>
    <img :src='imgSrc' alt="Avatar" class="avatar">
    <div class="avatarName">{{avatarName}}</div>
    <div class="avatarDesc">{{avatarDesc}}</div>
</div>
</template>

<script>
export default {
  name: 'MemberCard',
  props: ['imgSrc', 'avatarName', 'avatarDesc'],
  methods: {
  },
  mounted() {}
}
</script>

<style scoped>
.avatar {
  vertical-align: middle;
  width: 250px;
  height: 250px;
  border-radius: 50%;
}
.avatarName{
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 7px;
}
.avatarDesc{
    color: gray;
}
</style>
